<template>
  <content-layout
    :breadcrumbs-items="breadcrumbsItems"
    toolbar-enabled
    toolbar-flat
    :search-enabled="tab === 0"
    search-bar-in-toolbar
    :search-text.sync="searchText"
    search-debounce-enabled
  >
    <template #toolbar>
      <cz-button
        color="button"
        dark
        :title="$t('customer.userManagement.inviteUser')"
        @click="inviteUserDialog.show = true"
        :icon-src="mdiAccountMultiplePlusOutline"
        :icon-size="20"
      />
    </template>
    <div class="py-6">
      <v-card rounded="lg" flat>
        <v-tabs v-model="tab">
          <v-tab v-for="tabItem in tabs" :key="tabItem.id">
            {{ tabItem.title }}
          </v-tab>
        </v-tabs>
      </v-card>
      <cz-data-table v-bind="dataTable" :columns="filteredColumns" class="mt-2">
        <template #item.profilePicture="{ item }">
          <cz-avatar
            :size="35"
            color="primary"
            :name="item.fullName"
            initials-color="white"
            initials-size="font-weight-semibold text-subtitle-1 text-uppercase"
          />
        </template>
        <template #item.roles="{ item }">
          <cz-chip
            v-for="role in item.roles"
            :key="role._id"
            color="primary"
            class="ml-2"
            :text="role.roleName"
            small
            :removeable="
              item._id !== currentUser._id &&
              role.role !== 'customer-admin' &&
              tab === 0
            "
            @remove="onRemoveRole(item, role)"
          />
        </template>
        <template #item.invitedBy="{ item }">
          <div v-if="item.user">{{ item.user.fullName }}</div>
        </template>
        <template #item.actions="{ item }">
          <cz-menu-button
            v-if="actionsMenuItems && actionsMenuItems.length"
            :button-icon-src="mdiDotsHorizontal"
            flat
            :menu-items="actionsMenuItems"
            @action:clicked="onActionClicked($event, item)"
            clickable
          />
          <!-- <v-menu offset-y :nudge-bottom="10">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    text
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="ml-1" :size="18">{{
                      mdiCommentQuestion
                    }}</v-icon>
                    {{ $t('admin.additionalDetailsTooltip') }}
                  </v-btn>
                </template>
                <v-card :max-width="350">
                  <v-card-text>
                    <div>
                      {{
                        $t('admin.lastUpdatedBy', [item.lastUpdatedBy.fullName])
                      }}
                    </div>
                    <div>
                      {{
                        $t('admin.lastUpdatedAt', [
                          format(
                            new Date(item.updatedAt),
                            'do MMMM, yyyy hh:mm'
                          )
                        ])
                      }}
                    </div>
                    <div v-if="item.reason" class="mt-4">
                      <div class="font-weight-semibold">
                        {{ `${$t('admin.reason')}:` }}
                      </div>
                      <div class="text-pre-wrap">{{ item.reason }}</div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu> -->
        </template>
      </cz-data-table>
    </div>
    <invite-user-dialog
      v-if="inviteUserDialog.show"
      v-model="inviteUserDialog.show"
      :allowed-invitation-types="allowedInvitationTypes"
      v-bind="inviteUserDialog"
      @close="inviteUserDialog.show = false"
      @on-invite="inviteUser"
    />
    <assign-role-dialog
      v-if="assignRoleDialog.show"
      v-model="assignRoleDialog.show"
      v-bind="assignRoleDialog"
      @close="assignRoleDialog.show = false"
      @done="assignUserRole"
    />
    <cz-prompt-dialog
      v-if="removeRoleDialog.show"
      v-model="removeRoleDialog.show"
      v-bind="removeRoleDialog"
      @done="removeUserRole"
      @cancel="removeRoleDialog.show = false"
    />
  </content-layout>
</template>

<script>
import {
  CzAvatar,
  CzMenuButton,
  CzButton,
  CzDataTable,
  CzPromptDialog,
  CzChip
} from '@/components';
import {
  getUsers,
  createInvitation,
  getInvitations,
  deleteUserRole,
  createUserRole,
  deleteInvitation,
  resendInvitation
} from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import {
  mdiDotsHorizontal,
  mdiPause,
  mdiEmailSync,
  mdiEmailRemove,
  mdiPlus,
  mdiAccountMultiplePlusOutline
} from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import RoleType from '@/shared/types/RoleType';
import ACTIVE_FEATURE from '@/shared/types/ActiveFeature';
export default {
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    InviteUserDialog: () => import('../components/InviteUserDialog.vue'),
    AssignRoleDialog: () => import('../components/AssignRoleDialog.vue'),
    CzAvatar,
    CzMenuButton,
    CzButton,
    CzChip,
    CzPromptDialog,
    CzDataTable
  },
  name: 'UsersManagementPage',
  created() {
    this.loadData();
  },
  computed: {
    ...mapFields('auth', ['accountId', 'currentUser', 'account']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.usersManagement'),
          disabled: true,
          exact: true
        }
      ];
    },
    actionsMenuItems() {
      if (this.tab === 0) {
        return [
          {
            id: 'assign-role',
            title: this.$t('customer.userManagement.actions.assignNewRole'),
            icon: mdiPlus
          },
          {
            id: 'suspend_user',
            title: this.$t('customer.userManagement.actions.suspend'),
            icon: mdiPause
          }
        ];
      } else {
        return [
          {
            id: 'resend_invite',
            title: this.$t('customer.userManagement.actions.resentInvite'),
            icon: mdiEmailSync
          },
          {
            divider: true
          },
          {
            id: 'cancel_invite',
            title: this.$t('customer.userManagement.actions.cancelInvite'),
            icon: mdiEmailRemove
          }
        ];
      }
    },
    filteredColumns() {
      return this.dataTable.columns.filter(
        (item) => !this.tabs[this.tab].excludedColumns.includes(item.value)
      );
    },
    allowedInvitationTypes() {
      const invitationTypes = [
        RoleType.CUSTOMER_ADMIN,
        RoleType.CUSTOMER_AUTHORIZER,
        RoleType.CUSTOMER_OBSERVER,
        RoleType.CUSTOMER_PURCHASER
      ];

      if (
        this.account?.activeFeatures?.includes(
          ACTIVE_FEATURE.ORDER_CREATION_WITH_ITEMS
        )
      ) {
        invitationTypes.push(RoleType.CUSTOMER_CATALOG_ADMIN);
      }
      return invitationTypes;
    }
  },
  data() {
    return {
      tab: 0,
      mdiDotsHorizontal,
      mdiAccountMultiplePlusOutline,
      mdiPause,
      searchText: '',
      tabs: [
        {
          id: 'active-users',
          title: this.$t('customer.userManagement.activeUsers'),
          excludedColumns: ['invitedBy']
        },
        {
          id: 'pending-invites',
          title: this.$t('customer.userManagement.pendingInvites'),
          excludedColumns: ['fullName']
        }
      ],
      dataTable: {
        loading: false,
        height: 'calc(100vh - 400px)',
        loadingText: this.$t('common.loading'),
        noDataText: this.$t('common.noData'),
        columns: [
          {
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            width: 60,
            value: 'profilePicture',
            align: 'center'
          },
          {
            text: this.$t('customer.userManagement.userFullName'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'fullName'
          },
          {
            text: this.$t('customer.userManagement.userEmail'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'email'
          },
          {
            text: this.$t('customer.userManagement.userRoles'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'roles'
          },
          {
            text: this.$t('customer.userManagement.invitedBy'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'invitedBy'
          },
          {
            text: '',
            value: 'actions'
          }
        ],
        items: []
      },
      inviteUserDialog: {
        show: false,
        loading: false,
        title: this.$t('customer.userManagement.inviteUserTitle'),
        errorMessage: ''
      },
      assignRoleDialog: {
        show: false,
        loading: false,
        errorMessage: null,
        user: null
      },
      removeRoleDialog: {
        show: false,
        loading: false,
        title: this.$t('customer.userManagement.removeRoleDialogTitle'),
        message: this.$t('customer.userManagement.removeRoleDialogMessage'),
        doneButtonTitle: this.$t(
          'customer.userManagement.removeRoleDialogDoneButton'
        ),
        selectedRoleId: null,
        user: null
      }
    };
  },
  methods: {
    ...mapActions('ui', ['showErrorToastMessage', 'showSuccessToastMessage']),
    async loadData() {
      try {
        this.dataTable.loading = true;
        this.dataTable.items = [];
        if (this.tab === 0) {
          const { data } = await getUsers({
            accountId: this.accountId,
            search: this.searchText || undefined
          });
          this.dataTable.items = data.map((item) => {
            return {
              _id: item._id,
              email: item.email,
              fullName: item.fullName,
              roles: item.roles
            };
          });
        } else {
          const { data } = await getInvitations({
            accountId: this.accountId,
            redeem: false,
            invitationType: { $ne: 'vendor-admin' }
          });
          this.dataTable.items = data.map((item) => {
            return {
              _id: item._id,
              email: item.inviteeEmail,
              fullName: item.inviteeEmail,
              roles: item.assignedRolesNames.map((roleName) => {
                return {
                  roleName
                };
              }),
              user: item.user
            };
          });
        }
      } finally {
        this.dataTable.loading = false;
      }
    },
    async inviteUser(data) {
      try {
        this.inviteUserDialog.loading = true;
        await createInvitation({
          inviteeEmail: data.email,
          assignedRoles: [data.role.role],
          invitationType: data.role.invitationType,
          accountId: this.accountId
        });
        this.inviteUserDialog.show = false;
      } catch (error) {
        this.inviteUserDialog.errorMessage = error.message;
      } finally {
        this.inviteUserDialog.loading = false;
      }
    },
    async onActionClicked(menuItem, item) {
      if (menuItem.id === 'cancel_invite') {
        try {
          await deleteInvitation(item._id, this.accountId);
          const index = this.dataTable.items.findIndex(
            (i) => i._id === item._id
          );
          if (index !== -1) {
            this.dataTable.items.splice(index, 1);
          }
        } catch (error) {
          this.showErrorToastMessage(error.message);
        } finally {
        }
      } else if (menuItem.id === 'resend_invite') {
        await resendInvitation(item._id, this.accountId);
        this.showSuccessToastMessage(
          this.$t('customer.userManagement.inviteResent')
        );
      } else if (menuItem.id === 'assign-role') {
        this.showAssignUserRoleDialog(item);
      }
    },
    showAssignUserRoleDialog(user) {
      this.assignRoleDialog.user = cloneDeep(user);
      this.assignRoleDialog.show = true;
    },
    async assignUserRole(role, user) {
      try {
        this.assignRoleDialog.loading = true;
        const userRole = await createUserRole({
          accountId: this.accountId,
          role,
          userId: user._id
        });

        const _user = this.dataTable.items.find(
          (item) => item._id === user._id
        );
        _user.roles.push(userRole);
        this.assignRoleDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('customer.userManagement.roleAssignedSuccessMessage')
        );
      } finally {
        this.assignRoleDialog.loading = false;
      }
    },
    onRemoveRole(user, role) {
      this.removeRoleDialog.message = this.$t(
        'customer.userManagement.removeRoleDialogMessage',
        [role.roleName, user.fullName]
      );
      this.removeRoleDialog.user = user;
      this.removeRoleDialog.selectedRoleId = role._id;
      this.removeRoleDialog.show = true;
    },
    async removeUserRole() {
      try {
        this.removeRoleDialog.loading = true;
        await deleteUserRole(this.removeRoleDialog.selectedRoleId, {
          accountId: this.accountId
        });
        this.removeRoleDialog.show = false;
        const index = this.removeRoleDialog.user.roles.findIndex(
          (item) => item._id === this.removeRoleDialog.selectedRoleId
        );
        if (index !== -1) {
          this.removeRoleDialog.user.roles.splice(index, 1);
        }

        this.showSuccessToastMessage(
          this.$t('customer.userManagement.roleRemovedSuccessMessage')
        );
      } finally {
        this.removeRoleDialog.loading = false;
      }
    }
  },
  watch: {
    tab: {
      immediate: true,
      handler() {
        this.loadData();
      }
    },
    'inviteUserDialog.show': {
      handler(value) {
        if (!value) {
          this.inviteUserDialog.errorMessage = '';
        }
      }
    },
    'removeRoleDialog.show': {
      handler(val) {
        if (!val) {
          this.removeRoleDialog.selectedRoleId = null;
          this.removeRoleDialog.message = null;
        }
      }
    },
    searchText: {
      handler() {
        this.loadData();
      }
    }
  }
};
</script>

<style></style>
